<script>
import appConfig from '@src/app.config'

export default {
    page: {
        titleTemplate(title) {
            title = typeof title === 'function' ? title(this.$store) : title
            return title ? `${title} | ${appConfig.title}` : appConfig.title
        },
    },
    created() {
    }
}
</script>
<template>
    <div id="app">
        <vue-page-transition name="fade">
            <!-- <RouterView :key="$route.fullPath" /> -->
            <RouterView />
        </vue-page-transition>
    </div>
</template>

<style lang="scss">
@import '@design';
</style>
