import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import createMultiTabState from 'vuex-multi-tab-state';

import modules from './modules'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  getters,
  mutations,
  state,
  actions,
  strict: process.env.NODE_ENV !== 'production',
  plugins: [createPersistedState(),createMultiTabState()]
})

export default store
